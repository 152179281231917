html,
body {
  margin: 0;
  padding: 0;
}

::selection {
  background: cyan;
  color: red;
}

.page {
  position: absolute;
  /* top: 0; */
  left: 0;
  right: 0;
}

@font-face {
  font-family: "RobotoMono";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Light.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Bold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoThin";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Thin.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoMedium";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-Medium.ttf");
  font-weight: normal;
}

.name-logo {
  color: black;
  position: sticky;
  text-decoration: none;
  padding-top: 3%;
  margin-left: 5%;
  font-family: "Courier";
  font-size: 2rem;
  z-index: 999999;
  /* cursor: pointer; */
}
@media screen and (min-width: 1824px) {
  .name-logo {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 800px) {
  .name-logo {
    font-size: 1.5em;
    padding-top: 5%;
  }
}

.linkStyle {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  padding-right: 14px;
}
.linkStyle:hover {
  text-decoration: line-through;
}

.navSelected {
  text-decoration: line-through;
}

.aboutLink {
  text-decoration: none;
  color: red;
}
.aboutLink:hover {
  text-decoration: line-through;
}

.boatLink {
  text-decoration: none;
  color: red;
  margin-left: 15%;
}
.boatLink:hover {
  text-decoration: line-through;
}

@media screen and (max-width: 800px) {
  .boatLink {
    margin-left: 5%;
  }
}

.projectPageTitle {
  font-family: "RobotoMedium";
  /* font-style: bold; */
  font-size: 28px;
  margin-left: 15%;
  margin-top: 5%;
}

.projectPageBody {
  font-family: "RobotoMono";
  font-size: 16px;
  margin-left: 15%;
  margin-top: 3%;
  width: 70%;
  line-height: 28px;
  margin-bottom: 3%;
}

.projectPageImg {
  width: 70%;
  margin-left: 15%;
  /* margin-top: 2%; */
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.projectPageVideo {
  width: 70%;
  margin-left: 15%;
}

.player-wrapper {
  position: relative;
  margin-left: 15%;
  /* width: 100%; */
  padding-top: 38.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 70%;
  /* justify-content: left; */
}

.player-wrapper-collectiveUnbelonging {
  position: relative;
  margin-left: 15%;
  /* width: 100%; */
  padding-top: 40.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 70%;
  /* justify-content: left; */
}

.player-wrapper-falsemirror {
  position: relative;
  margin-left: 15%;
  margin-bottom: 5%;
  /* width: 100%; */
  padding-top: 48.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 70%;
  /* justify-content: left; */
}

.player-wrapper-versace {
  position: relative;
  margin-left: 15%;
  /* width: 100%; */
  padding-top: 40.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 70%;
  margin-bottom: 2%;
}

@media screen and (max-width: 800px) {
  .projectPageTitle {
    font-size: 1.5rem;
    margin-left: 5%;
  }
  .projectPageBody {
    margin-left: 5%;
    width: 80%;
    font-size: 14px;
  }
  .projectPageImg {
    margin-left: 5%;
    width: 90%;
  }
  .projectPageVideo {
    margin-left: 5%;
    width: 90%;
  }
  .player-wrapper {
    width: 90%;
    margin-left: 5%;
    padding-top: 47.25%;
  }
  .player-wrapper-falsemirror {
    width: 90%;
    margin-left: 5%;
    padding-top: 59.25%;
  }
  .player-wrapper-collectiveUnbelonging, .player-wrapper-versace {
    width: 90%;
    margin-left: 5%;
    padding-top: 49.25%;
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.projTitle {
  position: absolute;
  /* display: inline; */
  /* padding: 1em; */
  /* background-color: rgba(58, 52, 42, 0.7); */
  color: black;
  font-family: "RobotoMono";
  font-size: 1.35rem;
  max-width: 90%;
  margin-top: 0%;
  left: 0.7%;
  transform: translate(0%, -110%);
  pointer-events: none;
  transition: opacity 100ms;
  opacity: 0;
  /* display: none; */
}

.projectImg {
  opacity: 1;
  transition: opacity 400ms;
  cursor: help;
}
.projectImg:hover {
  /* filter: invert(1); */
  opacity: 0.2;
  cursor: help;
}

.image-appear {
  opacity: 0.01;
}

.image-appear.image-appear-active {
  opacity: 1;
  transition: opacity 0.7s ease-in;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms linear 200ms;
}

.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms linear;
}

.icon {
  opacity: 0.6;
  transition: opacity 200ms linear;
}
.icon:hover {
  opacity: 1;
}
